import React from 'react';
import { useDispatch } from 'react-redux';
import { setSubtopic } from '../redux/actions/dataActions'; // Import your action creator
import '../styles/Sidebar.css';
import Notes from './Notes'; // Import the Notes component

const SidebarContainer = ({ groupedData, onSelectElement, selectedtopic }) => {
    const dispatch = useDispatch(); // Get dispatch function from Redux

    // Ensure selectedtopic is valid and groupedData[selectedtopic] is an array
    const elements = groupedData[selectedtopic] || [];

    const handleSelectElement = (element) => {
        dispatch(setSubtopic(element)); // Update the subtopic in the store
        onSelectElement(element); // Trigger any additional actions (e.g., open Notes)
    };

    return (
        <div className="sidebar">
            <h2 className="sidebar-title">Notes</h2>
            {elements.length > 0 ? (
                elements.map((element) => (
                    <div 
                        key={element} 
                        className="sidebar-item" 
                        onClick={() => handleSelectElement(element)}
                    >
                        {element}
                    </div>
                ))
            ) : (
                <div className="sidebar-item">No items available</div>
            )}

            {/* Render Notes component conditionally */}
            {/* {selectedSubtopic && <Notes />} */}
        </div>
    );
};

export default SidebarContainer;
