import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { setModule } from '../redux/actions/dataActions';
import MCQ from './MCQ';
import FillInTheBlankQuestion from './FIB';
import SingleCorrect from './SingleCorrect';
import MatchTheFollowingQuestion from './MatchTheFollowingQuestion';
import MultiMatchQuestion from './MultiMatchQuestion';

const ModulePage = () => {
    const [incorrect, setIncorrect] = useState([]);
    const [currentObject, setCurrentObject] = useState(null);
    const [objects, setObjects] = useState([]);
    const selectedTopic = useSelector((state) => state.data.topic);
    const selectedSubtopic = useSelector((state) => state.data.subtopic);
    const selectedModule = useSelector((state) => state.data.module);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userId = useSelector((state) => state.data.userId);

    const progress = objects.length > 0 ? ((objects.length - incorrect.length) / objects.length) * 100 : 0;

    useEffect(() => {
        const fetchObjects = async () => {
            if (!selectedSubtopic || !selectedModule) {
                console.warn('Subtopic or Module not selected');
                return;
            }

            try {
                const response = await axios.get(`${process.env.REACT_APP_BE_URL}/task`, {
                    params: {
                        topic: selectedTopic,
                        subtopic: selectedSubtopic,
                        module: selectedModule,
                    },
                });
                const fetchedObjects = response.data;

                if (fetchedObjects.length === 0) {
                    console.log("Completed the module");
                    navigate('/maincontent');
                    return;
                }

                setObjects(fetchedObjects);
                setIncorrect(fetchedObjects);
                pickRandomObject(fetchedObjects);
            } catch (error) {
                console.error('Error fetching objects:', error);
            }
        };

        fetchObjects();
    }, [selectedSubtopic, selectedModule, navigate]);

    const pickRandomObject = useCallback((objects) => {
        if (objects.length > 0) {
            const randomIndex = Math.floor(Math.random() * objects.length);
            setCurrentObject(objects[randomIndex]);
        } else {
            navigate('/maincontent');
        }
    }, [navigate]);

    const handleNext = useCallback((wasCorrect) => {
        if (wasCorrect) {
            const updatedIncorrect = incorrect.filter(obj => obj !== currentObject);
            setIncorrect(updatedIncorrect);

            if (updatedIncorrect.length === 0) {
                axios.patch(`${process.env.REACT_APP_BE_URL}/progress/${userId}/add-module`, {
                    subtopic: selectedSubtopic,
                    moduleToAdd: selectedModule
                })
                .then(() => {
                    dispatch(setModule(null));
                    alert("Congratulations! You have successfully Completed the module.");
                    navigate('/maincontent');
                })
                .catch((error) => {
                    console.error('Error updating progress:', error);
                });
                return;
            }
        }
        pickRandomObject(incorrect);
    }, [incorrect, currentObject, selectedSubtopic, selectedModule, userId, dispatch, navigate, pickRandomObject]);

    return (
        <div className="module-page">
            <div className="progress-bar-container">
                <div className="progress-bar" style={{ width: `${progress}%` }}></div>
            </div>
            {currentObject && currentObject.type === "MCQ" && (
                <MCQ 
                    object={currentObject} 
                    progressBar={progress} 
                    onAnswerCheck={(wasCorrect) => handleNext(wasCorrect)}
                />
            )}
            {currentObject && currentObject.type === "FIB" && (
                <FillInTheBlankQuestion 
                    object={currentObject} 
                    progressBar={progress} 
                    onAnswerCheck={(wasCorrect) => handleNext(wasCorrect)}
                />
            )}
            {currentObject && currentObject.type === "SingleCorrect" && (
                <SingleCorrect 
                    object={currentObject} 
                    progressBar={progress} 
                    onAnswerCheck={(wasCorrect) => handleNext(wasCorrect)}
                />
            )}
            {currentObject && currentObject.type === "match_the_following" && (
                <MatchTheFollowingQuestion 
                    object={currentObject} 
                    progressBar={progress} 
                    onAnswerCheck={(wasCorrect) => handleNext(wasCorrect)}
                />
            )}
            {currentObject && currentObject.type === "multimatchquestion" && (
                <MultiMatchQuestion 
                    object={currentObject} 
                    progressBar={progress} 
                    onAnswerCheck={(wasCorrect) => handleNext(wasCorrect)}
                />
            )}
        </div>
    );
};

export default ModulePage;
