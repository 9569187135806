import React from 'react';
import '../styles/index.css';

const ClassOption = ({ label, isSelected, onSelect }) => {
    return (
        <div 
            className={`goal-option ${isSelected ? 'bg-cyan-100 rounded-lg p-2' : ''} flex items-center justify-start my-2 cursor-pointer`} 
            onClick={() => onSelect(label)}
        >
            <input 
                type="radio" 
                name="goal" 
                checked={isSelected} 
                onChange={() => onSelect(label)}
                className="mr-2 cursor-pointer"
            />
            <label 
                className="cursor-pointer"
                onClick={() => onSelect(label)}
            >
                {label}
            </label>
        </div>
    );
};

export default ClassOption;
