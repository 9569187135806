import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import logoImage from '../images/Logo.png';
import '../styles/Navbar.css';

const Navbar = ({ level, subject, topic }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  // const level = useSelector((state) => state.data.level);
  // const subject = useSelector((state) => state.data.subject);  
  // const topic = useSelector((state) => state.data.topic);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleUserMenu = () => {
    setUserMenuOpen(!userMenuOpen);
  };

  const handleLogout = () => {
    navigate('/');
  };

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <nav className={`custom-navbar ${menuOpen ? 'open' : ''}`}>
      <Link to="/" className="custom-navbar-logo">
        <img src={logoImage} className="custom-navbar-logo-img" alt="Logo" />
        <p className="custom-navbar-logo-text">MrSivaScience Tutoring</p>
      </Link>
      <div className="custom-navbar-center">
        {level && (
          <>
            <span
              className="breadcrumb-link"
              onClick={() => handleNavigate('/')}
            >
              {level}
            </span>
            {subject && (
              <>
                {'  ||  '}
                <span
                  className="breadcrumb-link"
                  onClick={() => handleNavigate('/picksubject')}
                >
                  {subject}
                </span>
              </>
            )}
            {topic && (
              <>
                {'   ||   '}
                <span
                  className="breadcrumb-link"
                  onClick={() => handleNavigate('/picktopic')}
                >
                  {topic}
                </span>
              </>
            )}
          </>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
