import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSubtopic, setModule, setTopic } from '../redux/actions/dataActions';
import axios from 'axios';
import '../styles/LevelItem.css';

const ContentArea = React.memo(({ topics, subtopics, modules }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { selectedSubject, selectedTopic, selectedSubtopic, selectedModule, userId } = useSelector((state) => ({
        selectedSubject: state.data.subject,
        selectedTopic: state.data.topic,
        selectedSubtopic: state.data.subtopic,
        selectedModule: state.data.module,
        userId: state.data.userId,
    }));    
    const [progress, setProgress] = useState(null);

    useEffect(() => {
        const fetchUserProgress = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BE_URL}/progress`, {
                params: { "userId": userId }
                });
                setProgress(response.data[0]);
                // console.log(response.data[0].subtopicsCovered);
            } catch (error) {
                console.error('Error fetching user progress:', error);
            }
        };

        fetchUserProgress();
    }, [userId]); 


    const handleTopicClick = useCallback((topic) => {
        if (selectedTopic === topic) {
            dispatch(setTopic(null));
        } else {
            dispatch(setTopic(topic));
        }
    },[selectedTopic, dispatch]);

    const handleSubtopicClick = useCallback((subtopic) => {
        // if (selectedSubtopic === subtopic) {
        //     dispatch(setSubtopic(null));
        // } else {
        //     dispatch(setSubtopic(subtopic));
        // }
        dispatch(setSubtopic(subtopic));
        // dispatch(setModule(module));
    },[dispatch]);

    const handleModuleClick = useCallback((module) => {
        const moduleList = modules[selectedTopic]?.[selectedSubtopic] || [];
        const currentIndex = moduleList.indexOf(module);

        const previousModule = currentIndex > 0 ? moduleList[currentIndex - 1] : null;
        const previousModuleLocked = previousModule ? moduleLock(selectedSubtopic,previousModule) : 0;
        
        if (previousModuleLocked === 0 || previousModule === null) {
            dispatch(setModule(module));
            navigate(`/module/${module}`);
        } else {
            alert('Please complete the above module to unlock this');
        }
    },[modules, selectedTopic, selectedSubtopic, dispatch, navigate]);


    const subTopicLock = (subtopic) => {
        if (progress && progress.subtopicsCovered) {
            return progress.subtopicsCovered[subtopic] !== undefined ? 0 : 1;
        }
        return 1;
    };

    const moduleLock = (subtopic,module) => {
        if (progress && progress.subtopicsCovered && progress.subtopicsCovered[subtopic]) {
            const coveredModules = progress.subtopicsCovered[subtopic] || [];
            return coveredModules.includes(module) ? 0 : 1;
        }
        return 1;
    };

    const renderedSubtopics = useMemo( () => { 
        return selectedTopic ? (
        <div className="levels-container relative">
            {(topics[selectedTopic] || []).map((subtopic, index) => (
                <div key={index} onClick={() => handleSubtopicClick(subtopic)}>
                    <div
                        className="sticky top-0 z-40 bg-slate-900 font-bold text-2xl italic text-white py-2 flex items-center justify-center"
                        // onClick={() => handleSubtopicClick(subtopic)}
                    >
                        <span className="relative mx-4">{subtopic}</span>
                    </div>
                    <div className="levels-container">
                        {(modules[selectedTopic]?.[subtopic] || []).map((module, modIndex) => {
                            // console.log(modIndex, "-",  moduleLock(module));
                            const isLocked = (moduleLock(subtopic,module) === 1);
                            const isFirstLocked = (isLocked && (modIndex === 0 || moduleLock(subtopic, modules[selectedTopic][subtopic][modIndex - 1]) !== 1));
                            // console.log(isLocked);
                            // console.log(isFirstLocked);
                            return (
                                <div
                                    key={modIndex}
                                    className={`level-item ${isLocked ? (isFirstLocked ? 'first-locked' : 'locked') : 'unlocked'}`}
                                    onClick={() => handleModuleClick(module)}
                                >
                                    {module}
                                </div>
                            );
                        })}
                    </div>
                </div>
            ))}
        </div>
    ) : <div className="flex items-center justify-center min-h-screen">
            <h2 className="text-center text-green-400 text-xl font-semibold">
                Please click the logo and follow the process :)
            </h2>
        </div> },[selectedTopic, topics, modules, handleSubtopicClick, handleModuleClick] );
    

    return (
        <div className="content-area">
        {progress ?(selectedSubject ? (
            selectedTopic ? (
                <div>
                    {/* <Header subject={selectedTopic} /> */}
                    {renderedSubtopics}
                </div>
            ) : (
                <div>
                    <h2>Topics</h2>
                    {/* <div className="flex flex-col items-center p-5"> */}
                    <div className="levels-container">
                        {Object.keys(topics).map((topic, index) => (
                            <div key={index}>
                                <span
                                    className="level-item"
                                    onClick={() => handleTopicClick(topic)}
                                >
                                    {topic}
                                </span>
                                {/* <div className="subtopics-container">
                                    {(subtopics[topic] || []).map((subtopic, subIndex) => (
                                        <div key={subIndex} className="subtopic-name">
                                            {subtopic}
                                        </div>
                                    ))}
                                </div> */}
                            </div>
                        ))}
                    </div>
                </div>
            )
        ) : (
            <div className="flex items-center justify-center min-h-screen">
                <h2 className="text-center text-green-400 text-xl font-semibold">
                Please click the logo and follow the process :)
                </h2>
            </div>

        )):<div>Loading...</div> }
    </div>
    
    );
});

export default ContentArea;
