import React, { useState, useEffect } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const ItemType = 'ANSWER';

const DraggableAnswer = ({ text }) => {
    const [{ isDragging }, drag] = useDrag({
        type: ItemType,
        item: { text },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    return (
        <div
            ref={drag}
            className={`p-2 bg-gray-800 text-white flex items-center justify-center rounded-lg cursor-pointer w-full ${
                isDragging ? 'opacity-50' : 'opacity-100'
            }`}
            style={{ minHeight: '70px' }}
        >
            {text}
        </div>
    );
};

const DroppableArea = ({ acceptedAnswer, onDrop, isCorrect, isIncorrect }) => {
    const [{ isOver }, drop] = useDrop({
        accept: ItemType,
        drop: (item) => {
            if (!acceptedAnswer) {  // Allow drop only if there's no answer already
                onDrop(item);
            }
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    });

    const isEmpty = !acceptedAnswer;

    return (
        <div
            ref={drop}
            className={`p-2 rounded-lg w-full h-12 flex items-center justify-center border-2 ${
                isCorrect ? 'border-green-500' : isIncorrect ? 'border-red-500' : 'border-gray-300'
            }`}
            style={{ minHeight: '70px', backgroundColor: isOver ? 'rgba(255, 255, 255, 0.1)' : 'rgba(55, 65, 81, 1)' }}
        >
            <span className={`${isEmpty ? 'text-gray-400' : 'text-white'}`}>
                {acceptedAnswer || 'Drag your answer'}
            </span>
        </div>
    );
};

const MultiMatchQuestion = ({ object, progressBar, onAnswerCheck }) => {
    const { question, options, answer } = object;
    const [droppedAnswers, setDroppedAnswers] = useState(Array(answer.length).fill(null));
    const [shuffledAnswers, setShuffledAnswers] = useState([]);
    const [isChecked, setIsChecked] = useState(false);
    const [feedback, setFeedback] = useState(false);
    const [showNextButton, setShowNextButton] = useState(false);
    const [correctIndexes, setCorrectIndexes] = useState([]);

    // Function to shuffle an array
    const shuffleArray = (array) => {
        let shuffled = [...array];
        for (let i = shuffled.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
        }
        return shuffled;
    };

    useEffect(() => {
        setDroppedAnswers(Array(answer.length).fill(null)); // Reset dropped answers
        setShuffledAnswers(shuffleArray([...new Set(answer)])); // Shuffle unique answers and set them
        setIsChecked(false);
        setCorrectIndexes([]);
        setFeedback(false);
        setShowNextButton(false);
    }, [options, answer]);

    const handleDrop = (item, index) => {
        const updatedDroppedAnswers = [...droppedAnswers];
        updatedDroppedAnswers[index] = item.text;
        setDroppedAnswers(updatedDroppedAnswers);
    };

    const handleCheckAnswer = () => {
        let correct = [];
        droppedAnswers.forEach((droppedAnswer, index) => {
            const cleanedAnswer = droppedAnswer?.toLowerCase().replace(/\s+/g, '');
            const cleanedCorrectAnswer = object.answer[index]?.toLowerCase().replace(/\s+/g, '');
            if (cleanedAnswer === cleanedCorrectAnswer) {
                correct.push(index);
            }
        });
        setCorrectIndexes(correct);
        setIsChecked(true);
        setFeedback(correct.length === options.length);
        setShowNextButton(true);
    };

    const handleReset = () => {
        // Reset the answers to initial state
        setDroppedAnswers(Array(answer.length).fill(null));
        setShuffledAnswers(shuffleArray([...new Set(answer)])); // Shuffle answers on reset
        setIsChecked(false);
        setCorrectIndexes([]);
        setFeedback(false);
        setShowNextButton(false);
    };

    const handleNextQuestion = () => {
        const correct = droppedAnswers.every((droppedAnswer, index) => droppedAnswer === answer[index]);
    
        if (correct) {
            onAnswerCheck(true);
        } else {
            onAnswerCheck(false);
            setIsChecked(false);
            setCorrectIndexes([]);
            setDroppedAnswers(Array(answer.length).fill(null));
            setShowNextButton(false);
        }
    
        // // Reset states for the next question
        // setDroppedAnswers(Array(answer.length).fill(null));
        // setShuffledAnswers(shuffleArray([...new Set(answer)])); // Shuffle answers for the next question
        // setShowNextButton(false);
        // setIsChecked(false);
        // setCorrectIndexes([]);
        // setFeedback(false);
    };

    let imageIndex = 0;
    return (
        <DndProvider backend={HTML5Backend}>
            <div className="p-5 text-center bg-gray-900 text-white min-h-screen flex flex-col justify-center items-center">
                <div className="w-full max-w-4xl">
                    <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
                        <div className="bg-green-600 h-2.5 rounded-full" style={{ width: `${progressBar}%` }}></div>
                    </div>
                    <div className="mb-5">
                        <div className="text-lg font-semibold mb-2">
                            {object.question.split(/({image\d+})/g).map((part, index) => {
                                    if (part.match(/{image\d+}/)) {
                                        const match = part.match(/{image(\d+)}/);
                                        if (match) {
                                            const imageKey = imageIndex;
                                            const imageUrl = object.image ? object.image[imageKey] : null;
                                            if (imageUrl) {
                                                const imageElement = (
                                                    // eslint-disable-next-line jsx-a11y/img-redundant-alt
                                                    <img
                                                        key={`image-${imageIndex}`}
                                                        src={imageUrl}
                                                        alt={`Image ${imageKey}`}
                                                        className="inline-block mx-2 "
                                                    />
                                                );
                                                imageIndex += 1;
                                                return imageElement;
                                            }
                                        }
                                    } else {
                                        return part;
                                    }
                                    return null;
                                })}
                        </div>
                    </div>
                    <div className="grid grid-cols-3 gap-4 mb-5">
                        {/* Column 1: Items */}
                        <div className="flex flex-col space-y-2">
                            {options.map((option, index) => (
                                <div
                                    key={index}
                                    className={`p-2 bg-gray-700 text-white rounded-lg border-2 w-full ${
                                        isChecked && correctIndexes.includes(index) ? 'border-green-500' : ''
                                    }`}
                                    style={{ minHeight: '70px' }}
                                >
                                    {option}
                                </div>
                            ))}
                        </div>

                        {/* Column 2: Drop Area */}
                        <div className="flex flex-col space-y-2">
                            {options.map((_, index) => (
                                <DroppableArea
                                    key={index}
                                    acceptedAnswer={droppedAnswers[index]}
                                    onDrop={(item) => handleDrop(item, index)}
                                    isCorrect={isChecked && correctIndexes.includes(index)}
                                    isIncorrect={isChecked && !correctIndexes.includes(index)}
                                />
                            ))}
                        </div>

                        {/* Column 3: Draggable Answers / Correct Answers */}
                        <div className="flex flex-col space-y-2">
                            {isChecked ? (
                                answer.map((ans, index) => (
                                    <div
                                        key={index}
                                        className={`p-2 bg-gray-700 text-white rounded-lg border-2 flex items-center justify-center ${
                                            correctIndexes.includes(index)
                                                ? 'border-green-500 bg-green-400 font-medium text-black'
                                                : 'border-red-500 bg-red-400 font-medium text-black'
                                        }`}
                                        style={{ minHeight: '70px' }}
                                    >
                                        {ans}
                                    </div>
                                ))
                            ) : (
                                shuffledAnswers.map((ans, index) => (
                                    <DraggableAnswer key={index} index={index} text={ans} />
                                ))
                            )}
                        </div>
                    </div>
                    {feedback && progressBar===100  && <p className="text-green-500 mb-4 font-semibold">Congratulations! You have completed the module. <br/> Redirecting to the main page ..</p> }
                    {feedback && progressBar!==100 && <p className="text-green-500 mb-4 font-semibold">Great job!</p>}
                    {!feedback && isChecked && <p className="text-red-500 mb-4 font-semibold">Incorrect!</p>}
                    {isChecked && !feedback && !!object.ref.length && object.ref[0]!=="" && (
                        <div>
                            <h3 className="text-lg font-medium mb-2 text-yellow-300">Explanation:</h3>
                            {object?.ref?.map((refp, index) => (
                                <p key={index} className="text-gray-200 italic mb-1">{refp}</p>
                            ))}
                        </div>
                    )}
                    <div className="mt-5 flex space-x-4 justify-center items-center">
                        
                        {!showNextButton ? (
                            <button
                                className="px-6 py-2 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600"
                                onClick={handleCheckAnswer}
                            >
                                Check
                            </button>
                        ) : (
                            <button
                                className="px-6 py-2 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600"
                                onClick={handleNextQuestion}
                            >
                                Next
                            </button>
                        )}
                        {!showNextButton && <button
                            className="px-6 py-2 bg-yellow-500 text-white font-semibold rounded-lg hover:bg-yellow-600"
                            onClick={handleReset}
                        >
                            Reset
                        </button>}
                    </div>
                    
                </div>
            </div>
        </DndProvider>
    );
};

export default MultiMatchQuestion;
