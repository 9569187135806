import React, { useState, useEffect } from 'react';

const FillInTheBlankQuestion = ({ object, progressBar, onAnswerCheck }) => {
    const [userAnswers, setUserAnswers] = useState([]);
    const [isAnswerChecked, setIsAnswerChecked] = useState(false);
    const [allAnswersCorrect, setAllAnswersCorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [feedback, setFeedback] = useState(null);

    useEffect(() => {
        const numberOfBlanks = (object.question.match(/{}/g) || []).length;
        setUserAnswers(Array(numberOfBlanks).fill(''));
        setIsAnswerChecked(false);
        setFeedback(null);
    }, [object]);

    const handleInputChange = (index, value) => {
        const updatedAnswers = [...userAnswers];
        updatedAnswers[index] = value;
        setUserAnswers(updatedAnswers);
    };

    const preprocessString = (str) => {
        return str
            .replace(/[^\w\s]|_/g, '')  
            .replace(/\s+/g, '')        
            .toLowerCase();
    };

    const validateAnswer = (userAnswer, correctAnswer) => {
        const normalizedUserAnswer = preprocessString(userAnswer);
        const normalizedCorrectAnswer = preprocessString(correctAnswer);
        const editDistanceThreshold = 1;

        const isUserAnswerSubstringOfCorrect = normalizedCorrectAnswer.includes(normalizedUserAnswer);
        const isCorrectAnswerSubstringOfUser = normalizedUserAnswer.includes(normalizedCorrectAnswer);
        const isWithinSizeRange = normalizedCorrectAnswer.length <= normalizedUserAnswer.length && normalizedUserAnswer.length <= normalizedCorrectAnswer.length + editDistanceThreshold;

        return (isUserAnswerSubstringOfCorrect || isCorrectAnswerSubstringOfUser) && isWithinSizeRange;
    };

    const handleCheckAnswer = () => {
        setIsAnswerChecked(true);

        const allAnswersCorrect = userAnswers.every((userAnswer, index) => {
            const correctAnswer = object.answer[index];
            return validateAnswer(userAnswer, correctAnswer);
        });

        setAllAnswersCorrect(allAnswersCorrect);
        setIsCorrect(allAnswersCorrect);

        setFeedback(
            allAnswersCorrect
            ? progressBar==100 ? <p>Congratulations! You have completed the module. <br/> Redirecting to the main page ..</p>
            : <p>Great job! </p>
            : isCorrect
                ? (<>Doing Great, the exact answer is :
                    {object.answer.map((ans, index) => (
                            <div key={index}>Box {index + 1} - {ans}</div>
                        ))}
                </>)
                : (
                    <>
                        Incorrect! Correct answers:
                        {object.answer.map((ans, index) => (
                            <div key={index}>Box {index + 1} - {ans}</div>
                        ))}
                    </>
                )
        );
    };

    const handleNext = () => {
        if (!isAnswerChecked) {
            console.warn("Answer hasn't been checked yet.");
            return;
        }

        // console.log("onAnswer:", isCorrect);
        onAnswerCheck(isCorrect);
        if (!isCorrect){
            setUserAnswers([]);
            setIsAnswerChecked(false);
        }
    };

    const getInputClassName = (index) => {
        if (!isAnswerChecked) {
            return 'border-gray-300 bg-gray-800 text-white';
        }

        const userAnswer = userAnswers[index]?.trim();
        const correctAnswer = object.answer[index];
        const isCorrectAnswer = validateAnswer(userAnswer, correctAnswer);
        
        return isCorrectAnswer
            ? 'border-green-500 bg-green-500 text-white'
            : 'border-red-500 bg-red-500 text-white';
    };

    const renderParts = () => {
        let blankIndex = 0;
        let imageIndex = 0;

        return object.question.split(/({}|{image\d+})/g).map((part, index) => {
            if (part === '{}') {
                const currentIndex = blankIndex;                
                blankIndex += 1;
            
                return (
                    <input
                        key={`blank-${currentIndex}`}  
                        type="text"
                        value={userAnswers[currentIndex] || ''}
                        onChange={(e) => handleInputChange(currentIndex, e.target.value)}
                        className={`w-1/4 p-2 mx-1 rounded-lg border-2 ${getInputClassName(currentIndex)}`}
                        placeholder={`word ${currentIndex + 1}`}
                        disabled={isAnswerChecked}
                    />
                );
            } else if (part.match(/{image\d+}/)) {
                const match = part.match(/{image(\d+)}/);
                if (match) {
                    let imageKey = imageIndex;
                    const imageUrl = object.image[imageKey];
                    if (imageUrl) {
                        const imageElement = (
                            <img
                                key={`image-${imageIndex}`}
                                src={imageUrl}
                                alt={`Image ${imageKey}`}
                                className="inline-block mx-2 "
                            />
                        );
                        imageIndex += 1;
                        return imageElement;
                    }
                }
            } else {
                return part;  
            }
            return null;
        });
    };

    return (
        <div className="p-5 text-center bg-gray-900 text-white min-h-screen flex flex-col justify-center items-center">
            <div className="w-full max-w-lg">
                <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
                    <div className="bg-green-600 h-2.5 rounded-full" style={{ width: `${progressBar}%` }}></div>
                </div>
                <div className="mb-5">
                    <div className="text-lg font-semibold mb-2">
                        {renderParts()}
                    </div>
                </div>
                <div className="mt-5">
                    {!isAnswerChecked ? (
                        <button
                            className="px-6 py-2 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600"
                            onClick={handleCheckAnswer}
                            disabled={userAnswers.some(answer => answer?.trim() === '')}
                        >
                            Check
                        </button>
                    ) : (
                        <div className={`mb-4  font-semibold ${isCorrect ? "text-green-500" : "text-red-400"}`}>{feedback}</div>
                    )}
                </div>
                <div>
                    {isAnswerChecked && !isCorrect && !!object.ref.length && object.ref[0]!=="" &&(
                        <div>
                            <h3 className="text-lg font-medium mb-2 text-yellow-300">Explanation:</h3>
                            {object?.ref?.map((refp, index) => (
                                <p key={index} className="text-gray-200 italic mb-1">{refp}</p>
                            ))}
                        </div>
                    )}
                </div>
                {isAnswerChecked && (
                    <button
                        className="mt-5 px-6 py-2  bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600"
                        onClick={handleNext}
                    >
                        Continue
                    </button>
                )}
            </div>
        </div>
    );
};

export default FillInTheBlankQuestion;
