import React, { useEffect, useState } from 'react';
import '../styles/Notes.css';
import { FaTimes } from 'react-icons/fa';

const Notes = ({ contentLink, onClose}) => {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    const fetchHtml = async () => {
      try {
        // Fetch the HTML content from the provided URL
        const response = await fetch(contentLink);
        const text = await response.text();

        // Parse the HTML content
        const parser = new DOMParser();
        const doc = parser.parseFromString(text, 'text/html');

        // Modify image sources to be absolute URLs based on the contentLink
        const baseUrl = contentLink.substring(0, contentLink.lastIndexOf('/') + 1);
        // console.log(baseUrl);
        const images = doc.querySelectorAll('img');
        console.log(images);
        images.forEach(img => {
          if (img.src ) {
            img.src = baseUrl + img.getAttribute('src');
            // console.log(img.src);
          }
        });

        // Set the modified HTML content
        setHtmlContent(doc.body.innerHTML);

      } catch (error) {
        console.error('Error fetching HTML content:', error);
      }
    };

    fetchHtml();
  }, [contentLink]);

  return (
    <div className="notes-container">
        <button className="close-button" onClick={onClose}>
            <FaTimes />
        </button>
        <div className="notes" dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </div>
  );
};

export default Notes;
