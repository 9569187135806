import React, {useState, useEffect}from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ClassOption from './ClassOption';
import { setLevel } from '../redux/actions/dataActions';
import '../styles/index.css';
import axios from 'axios';
import Navbar from './Navbar';

const ClassPicker = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [levels, setLevels] = useState([]);
    const [select,setSelect] = useState(false);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BE_URL}/task`, {
            params: {}
        })
        .then((res) => {
            const uniqueLevels = [...new Set(res.data.map(item => item.level))];
            setLevels(uniqueLevels);
        })
        .catch((error) => {
            console.error("Error fetching topics:", error);
        });
    }, []);

    const handleSelect = (label) => {
        if (label) setSelect(true);
        dispatch(setLevel(label)); 
    };

    const goToSubjectPicker = () => {
        if (select){
            navigate('/picksubject');
        } 
        else{
            alert("Please choose one")
        }
    };

    return (
        <>
        <Navbar/>
        <div className=" p-5 rounded-lg shadow-lg max-w-md mt-40 mx-auto text-center font-semibold  bg-gray-1000 text-[white]">
            <h3 className="mb-5 text-xl">Pick a Level</h3>
            {
                levels.map((level, index)=>(
                    <ClassOption 
                        key = {index}
                        label= {level} 
                        onSelect={() => handleSelect(level)} 
                    />
                ))
            }{levels.length > 0 ?
            <button onClick={goToSubjectPicker} 
            className={`bg-green-500 text-white border-none py-2 px-4 mt-5 cursor-pointer rounded-lg text-md
            hover:bg-green-600`}>
                Set Level</button> : ""
                }
        </div>
        </>
    );
};

export default ClassPicker;
