import React, { useEffect, useState } from 'react';
import SidebarContainer from './SidebarContainer';
import ContentArea from './ContentArea';
import useFetchData from '../hooks/useFetchData';
import '../styles/MainContent.css';
import { useSelector, useDispatch } from 'react-redux';
import { setSubject, setTopic, setSubtopic } from '../redux/actions/dataActions';
import Navbar from './Navbar';
import Notes from './Notes'; // Import the Notes component
import axios from 'axios';

const MainContent = () => {
    const [openNotes, setOpenNotes] = useState(null); // State to manage the visibility of Notes
    const selectedLevel = useSelector((state) => state.data.level);
    const selectedTopic = useSelector((state) => state.data.topic);
    const selectedSubject = useSelector((state) => state.data.subject);
    const [allNotes, setallNotes] = useState({});

    const filters = {
        "level": selectedLevel,
        "subject": selectedSubject,
        "topic": selectedTopic,
    };
    const { groupedData, modulesData, uniqueSubjects } = useFetchData(filters);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(`${process.env.REACT_APP_BE_URL}/guide`, {
              params: filters
            });
            // response.jsonify
            // console.log(response.data); 
            setallNotes(response.data);
          } catch (error) {
            console.error('Error fetching data:', error); // Log the error
          }
        };
        fetchData(); // Call the async function
      }, [])

    const handleElementSelect = (element) => {
        dispatch(setSubtopic(element)); // Set subtopic in store
        const currentNote = allNotes.find(note => note.subtopic === element);

        if (currentNote) {
            const contentLink = currentNote.contentLink;
            dispatch(setSubtopic(element));
            setOpenNotes(contentLink); // Pass the content link to Notes
        }
    };

    const handleCloseNotes = () => {
        setOpenNotes(null); // Hide the Notes component
    };

    return (
        <>
            (openNotes && <Navbar level={selectedLevel} subject={selectedSubject} topic={selectedTopic} /> )
            <div className="main-content">
                {openNotes ? (
                    <Notes contentLink={openNotes} onClose={handleCloseNotes} />
                ) : (
                    <>
                        <ContentArea 
                            topics={groupedData}
                            subtopics={groupedData}
                            modules={modulesData}
                        />
                        <SidebarContainer 
                            groupedData={groupedData}
                            selectedtopic={selectedTopic}
                            onSelectElement={handleElementSelect} 
                        />
                    </>
                )}
            </div>
        </>
    );
};

export default MainContent;
