import React, {useState, useEffect}from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ClassOption from './ClassOption';
import { setSubject } from '../redux/actions/dataActions';
import '../styles/index.css';
import axios from 'axios';
import Navbar from './Navbar';

const SubjectPicker = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const selectedLevel = useSelector((state)=> state.data.level);
    const [subjects, setSubjects] = useState([]);
    const [select,setSelect] = useState(false);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BE_URL}/task`, {
            params: { level: selectedLevel }
        })
        .then((res) => {
            const uniqueSubjects = [...new Set(res.data.map(item => item.subject))];
            setSubjects(uniqueSubjects);
        })
        .catch((error) => {
            console.error("Error fetching topics:", error);
        });
    }, [selectedLevel]);


    const handleSelect = (label) => {
        if (label) setSelect(true);
        dispatch(setSubject(label)); 
    };

    const goToTopicPicker = () => {
        if (select){
            navigate('/picktopic');
        } 
        else{
            alert("Please choose one")
        }
    };

    return (
        <>
        <Navbar level={selectedLevel}  />
        <div className=" p-5 rounded-lg shadow-lg max-w-md mt-40 mx-auto text-center font-semibold  bg-gray-1000 text-[white]">
            <h3 className="mb-5 text-xl">Pick a Subject</h3>
            {
                subjects.map((subject, index)=>(
                    <ClassOption 
                        key = {index}
                        label= {subject} 
                        onSelect={() => handleSelect(subject)} 
                    />
                ))
            }
            {subjects.length > 0 ?
            <button onClick={goToTopicPicker} 
            className="bg-green-500 text-white border-none py-2 px-4 mt-5 cursor-pointer rounded-lg text-md hover:bg-green-600">
                Set Subject</button>: "" 
            }                
        </div>
        </>
    );
};

export default SubjectPicker;
